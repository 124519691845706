import Container from 'react-bootstrap/Container'
function Allergens({ direction, language }) {
    return (
        <Container>
            <p className='text-allergens mb-0' dir={direction}>{language === "AR" ? "يحتاج الأطفال من هم في سن 4-13 من (1200 - 1500) سعرة حرارية في المتوسط يوميا و تختلف الاحتياجات الفردية من السعرات الحرارية من شخص إلى آخر." : "Children aged 4-13 need an average of 1200-1500 calories per day, and individual calorie requirements may vary."}</p>
            <p className='text-allergens' dir={direction}>{language === "AR" ? " يحتاج البالغين إلى 2000 سعرة حرارية في المتوسط يومياً و تختلف الاحتياجات الفرديةمن السعرات الحرارية من شخص  إلى آخر." : "Adults need an average of 2000 calories per day, and individual calorie requirements may vary."}</p>
        </Container>
    );
}

export default Allergens;