import React, { useState } from 'react'
import NAV1 from './navbar1'
import MENU from './menu'
import OFFER from './offer'
import Placeholder from 'react-bootstrap/Placeholder'

function Body({ activeTab, direction, categories, loadingCategories, subCategories, loadingSubCategories, products, productsAr, loadingProducts, handleNav1Click, language, currency }) {

    const [showScrollButton, setShowScrollButton] = useState(false)

    window.onscroll = function () { scrollFunction() };
  
      function scrollFunction() {
          if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
              setShowScrollButton(true)
          } else {
              setShowScrollButton(false)
          }
      }
      const scrollToTop = () => {
          window.scrollTo(0, 0);
      };

    return (
        <div className="body pt-1">
            {
                loadingCategories ? (<Placeholder.Button xs={4} aria-hidden="true" />) : (<NAV1 activeTab={activeTab} direction={direction} categories={categories} handleNav1Click={handleNav1Click} language={language}/>)
            }
            { !loadingSubCategories && (activeTab === 139 || activeTab === 649) ? (<OFFER direction={direction} subCategories={subCategories} products={products} productsAr={productsAr} language={language} currency={currency}/>) : ('')}
            { !loadingSubCategories && (activeTab !== 139 && activeTab !== 649) ? (<MENU direction={direction} subCategories={subCategories} products={products} productsAr={productsAr} loadingProducts={loadingProducts} language={language} currency={currency}/>) : ('')}
            <button className={showScrollButton ? 'btn primary-bg btn-scroll-top' : 'btn btn-scroll-top d-none'} type="button" id='scroll-to-top' onClick={scrollToTop}> <img src='../assets/top.png' alt="top" /> </button>
        </div>
    );
}

export default Body;