import React, { useRef, useEffect, useState } from "react"
import NAV2 from './navbar2'
import Allergens from './allergens-alert'
import CatMenuList from './category_menu_list'
import { Scrollspy } from '@makotot/ghostui'
function Menu({ direction, subCategories, products, productsAr,loadingProducts, language, currency }) {

    const [scrollSpyElement, setscrollSpyElement,] = useState("")

    const sectionRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
    ];

    function handleScrollspy() {
        const activeNavItem = document.querySelector(".active-scroll-spy")
        const lastActiveItemId = "nav2-" + (subCategories.length - 1)
        const lastActiveItem = document.getElementById(lastActiveItemId)

        if (scrollSpyElement >= (subCategories.length-1)) {
            lastActiveItem.classList.add('active-scroll-spy')

            if(lastActiveItem !== null) setTimeout(function() {
                document.getElementById(lastActiveItem.id).scrollIntoView({ inline: "center" })
              }, 100)
        }
        else {
            if(subCategories.length!== 11) lastActiveItem.classList.remove('active-scroll-spy')
            if(activeNavItem !== null)
            setTimeout(function() {
                document.getElementById(activeNavItem.id).scrollIntoView({ inline: "center" })
              }, 100)
        }
    }

    function subcategoryProducts(subCategoryId) {
        let subCategoryProducts = ''
        if (language === "AR") subCategoryProducts = productsAr.filter(item => item.Id === subCategoryId)
        else subCategoryProducts = products.filter(item => item.Id === subCategoryId)
        if(subCategoryProducts[0])  return subCategoryProducts[0].Menu;
    }
    useEffect(() => {
        if (scrollSpyElement >= 0 && scrollSpyElement !== "") {
            handleScrollspy()
        }
        // eslint-disable-next-line
    }, [scrollSpyElement]) 

    return (
        <div className="body pt-1">
            <Scrollspy sectionRefs={sectionRefs} offset={-100}>
                {({ currentElementIndexInViewport }) => (
                    <div>
                        <NAV2 navItems={subCategories} direction={direction} language={language} currentElementIndexInViewport={currentElementIndexInViewport} />
                        <Allergens direction={direction} language={language}/>
                        <div data-cy="section-wrapper">
                            {
                                subCategories?.map((item, index) => {
                                    const subCategorProducts = subcategoryProducts(item.Id)
                                    return <CatMenuList key={"catmenulist-" + index} direction={direction} products={subCategorProducts} language={language} scrollId={index} sectionRefs={sectionRefs} setscrollSpyElement={setscrollSpyElement} currentElementIndexInViewport={currentElementIndexInViewport} currency={currency}/>
                                })
                            }
                        </div>
                    </div>
                )}
            </Scrollspy>
        </div>
    );
}

export default Menu;